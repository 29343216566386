import classNames from 'classnames'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LiblaryIcon } from 'src/assets/icons/customIcons/header-icons/Liblary'
import { LoudspeakerIcon } from 'src/assets/icons/customIcons/header-icons/Loudspeaker'
import { MessagesIcon } from 'src/assets/icons/customIcons/header-icons/Messages'
import { MoneyIcon } from 'src/assets/icons/customIcons/header-icons/Money'
import { LogIcon } from 'src/assets/icons/customIcons/menu-icons/Log'
import { LogoutIcon } from 'src/assets/icons/customIcons/menu-icons/Logout'
import { SettingsIcon } from 'src/assets/icons/customIcons/menu-icons/Settings'
import { ThemeIcon } from 'src/assets/icons/customIcons/menu-icons/Theme'
import { ThemeLightIcon } from 'src/assets/icons/customIcons/menu-icons/ThemeLight'
import { UserIcon } from 'src/assets/icons/customIcons/menu-icons/User'
import { actionProps } from 'src/helpers/fns'
import { useDropdown } from 'src/helpers/hooks'
import { useAuth, useAuthActions } from 'src/hooks/auth/auth'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useActiveSemester } from 'src/hooks/semesters'
import { sidebarStorage, useSidebar } from 'src/hooks/sidebar'
import { useUserState } from 'src/hooks/userState'
import { themeStorage, useTheme } from 'src/state/providers/Theme'
import UserPhoto from 'src/views/components/UserPhoto'
import NotificationsIcon from 'src/views/includes/header/NotificationsIcon'
import ProfileSwitcher from 'src/views/includes/header/ProfileSwitcher'
import { SurveyButton } from 'src/views/includes/survey/views/Survey'

export function Header(): JSX.Element {
  const locale = useLocale()
  const t = useTranslatable()
  const auth = useAuth()
  const actions = useAuthActions()
  const userState = useUserState()
  const activeSemester = useActiveSemester()
  const { show, setShow, ref, id } = useDropdown<HTMLDivElement>()
  const location = useLocation()
  const theme = useTheme()
  const isSidebarOpen = useSidebar()

  React.useEffect(() => void setShow(false), [location.key, setShow])

  return (
    <header className="sticky top-0 z-40 flex h-20 w-full items-center border-b-DEFAULT border-borderGrey bg-primaryWhite pl-[3px] pr-[10px] md:pl-[18px] md:pr-[30px] xl:pl-[28px] xl:pr-[40px]">
      <div className="flex flex-1 items-center justify-start">
        <button
          className="group relative flex h-[45px] flex-col items-center justify-center rounded-full px-[12px] pt-[6px] duration-500 ease-in-out hover:bg-hover-icon"
          onClick={() => sidebarStorage.setValue(!isSidebarOpen)}
        >
          <div
            className={`mb-[6px] h-px w-[24px] bg-primaryTextColor duration-300 ease-in-out group-hover:w-[15px] group-hover:translate-y-[3px] ${
              isSidebarOpen
                ? 'group-hover:translate-x-[-7px] group-hover:rotate-[-35deg]'
                : 'group-hover:translate-x-[7px] group-hover:rotate-[35deg]'
            }`}
          />
          <div className="mb-[6px] h-px w-[24px] bg-primaryTextColor duration-500 ease-in-out" />
          <div
            className={`mb-[6px] h-px w-[24px] bg-primaryTextColor duration-300 ease-in-out group-hover:w-[15px] group-hover:translate-y-[-3px] ${
              isSidebarOpen
                ? 'group-hover:translate-x-[-7px] group-hover:rotate-[35deg]'
                : 'group-hover:translate-x-[7px] group-hover:rotate-[-35deg]'
            }`}
          />
        </button>
        <span className="mx-12 hidden text-captionColor lg:block">
          {activeSemester?.name}
          {userState.data != null && auth.profile?.type !== 'UserProfileLecturer'
            ? ` | ${userState.data?.regime.name}`
            : null}
        </span>
      </div>
      <div className="relative flex flex-1 items-center justify-end">
        <div className="hidden rounded-xl2 bg-card lg:inline-flex">
          {auth.state === 'Authenticated' &&
            auth.profile.type === 'UserProfileStudent' &&
            userState.data?.regime?.functionalIsLimited === false && (
              <Link
                to={`/${locale}/student/billing/balance`}
                className="flex cursor-pointer items-center rounded-[18px] py-[5px] pl-2 pr-3 duration-300 ease-in-out hover:bg-hover-icon"
                data-testid="studentBillingBalanceHeader"
              >
                <div className="flex size-6 items-center justify-center rounded-full bg-[#999]">
                  <MoneyIcon />
                </div>
                <span className="ml-1 font-semibold text-primaryTextColor">
                  {userState.data?.billingBalance != null ? (userState.data.billingBalance / 100).toFixed(2) : ''}
                </span>
              </Link>
            )}
          <Link
            to={`/${locale}/library`}
            className="flex cursor-pointer rounded-[18px] px-2 py-[5px] hover:bg-hover-icon"
          >
            <div className="flex size-6 items-center justify-center rounded-full bg-[#85BDDB]">
              <LiblaryIcon />
            </div>
            {userState.data?.libraryBalance != null && (
              <span className="ml-1 font-semibold text-primaryTextColor">{userState.data.libraryBalance}</span>
            )}
          </Link>
        </div>
        <div className="mx-6 flex gap-[5px]">
          <SurveyButton />
          <Link
            to={`/${locale}/messages/inbox`}
            title={t('notification:messages')}
            className="relative cursor-pointer rounded-full p-[3px] hover:bg-hover-icon"
          >
            <MessagesIcon />
            {userState.data?.messagesUnread != null && userState.data.messagesUnread > 0 && (
              <div className="absolute !top-[-4px] right-[-7px] flex size-[16px] items-center justify-center rounded-full bg-lightSecondaryWarning">
                <span className="text-tiny text-white">
                  {userState.data != null && userState.data.messagesUnread > 0 && userState.data.messagesUnread > 99
                    ? '99+'
                    : userState.data.messagesUnread <= 99
                      ? userState.data.messagesUnread
                      : null}
                </span>
              </div>
            )}
          </Link>
          <Link
            to={`/${locale}/news`}
            title={t('notification:news')}
            className="relative cursor-pointer rounded-full p-[3px] hover:bg-hover-icon"
          >
            <LoudspeakerIcon />
            {userState.data?.newsUnread != null && userState.data.newsUnread > 0 && (
              <div className="absolute !top-[-4px] right-[-7px] flex size-[16px] items-center justify-center rounded-full bg-lightSecondaryWarning">
                <span className="text-tiny text-white">
                  {userState.data != null && userState.data.newsUnread > 0 && userState.data.newsUnread > 99
                    ? '99+'
                    : userState.data.newsUnread <= 99
                      ? userState.data.newsUnread
                      : null}
                </span>
              </div>
            )}
          </Link>
          <span className="relative cursor-pointer rounded-full p-[3px] hover:bg-hover-icon">
            <NotificationsIcon />
          </span>
        </div>
        <div className="hidden lg:block" data-testid="ProfileSwitcher/desktop">
          <ProfileSwitcher />
        </div>
        {/* profile-menu  */}
        <div ref={ref}>
          <a
            {...actionProps(() => void setShow(!show))}
            aria-expanded={show ? 'true' : 'false'}
            id={id}
            className="flex size-10 cursor-pointer items-center justify-center rounded-full bg-[#c99ff6] text-center"
            data-testid="profile-menu-button"
          >
            <UserPhoto height="100%" width="100%" user={auth.user} />
          </a>
          {auth.state === 'Authenticated' ? (
            <div
              className={classNames(
                'profile-menu absolute right-0 top-14 z-10 rounded-[8px] bg-primaryWhite pb-6 pt-6',
                {
                  block: show,
                  hidden: !show,
                }
              )}
              style={{ boxShadow: '0px 1px 14px #00000033' }}
              aria-labelledby={id}
            >
              <div className="mb-2 flex flex-col items-center justify-center lg:mb-7">
                <a className="mb-3 flex size-12 cursor-pointer items-center justify-center rounded-full bg-[#c99ff6] text-center">
                  <UserPhoto height="100%" width="100%" user={auth.user} />
                </a>
                <span className="mb-1 font-semibold text-primaryTextColor">{auth.user.fullName}</span>
                <div className="block lg:hidden" data-testid="ProfileSwitcher/mobile">
                  <ProfileSwitcher />
                </div>

                <div className="my-2 inline-flex justify-center rounded-xl2 bg-card">
                  {auth.state === 'Authenticated' &&
                    auth.profile.type === 'UserProfileStudent' &&
                    userState.data?.regime?.functionalIsLimited === false && (
                      <Link
                        to={`/${locale}/student/billing/balance`}
                        className="flex cursor-pointer items-center rounded-[18px] py-[5px] pl-2 pr-3 duration-300 ease-in-out hover:bg-hover-icon"
                      >
                        <div className="flex size-6 items-center justify-center rounded-full bg-[#999]">
                          <MoneyIcon />
                        </div>
                        <span className="ml-1 font-semibold text-primaryTextColor">
                          {userState.data?.billingBalance != null
                            ? (userState.data.billingBalance / 100).toFixed(2)
                            : ''}
                        </span>
                      </Link>
                    )}
                  <Link
                    to={`/${locale}/library`}
                    className="flex cursor-pointer rounded-[18px] px-2 py-[5px] hover:bg-hover-icon"
                  >
                    <div className="flex size-6 items-center justify-center rounded-full bg-[#85BDDB]">
                      <LiblaryIcon />
                    </div>
                    {userState.data?.libraryBalance != null && (
                      <span className="ml-1 font-semibold text-primaryTextColor">{userState.data.libraryBalance}</span>
                    )}
                  </Link>
                </div>
              </div>
              <ul className="mb-0 inline-block list-none items-start justify-start px-[27px]">
                <Link
                  to={`/${locale}/profile/personal`}
                  className="flex h-[42px] min-w-[250px] cursor-pointer items-center justify-start rounded-[8px] pl-4 hover:bg-hover-nav"
                >
                  <span>
                    <UserIcon />
                  </span>
                  <div className="ml-2 text-primaryTextColor no-underline">{t('person:profile')}</div>
                </Link>
                <Link
                  to={`/${locale}/profile/settings`}
                  className="flex h-[42px] min-w-[250px] cursor-pointer items-center justify-start rounded-[8px] pl-4 hover:bg-hover-nav"
                >
                  <SettingsIcon />
                  <div className="ml-2 text-primaryTextColor no-underline">{t('system:settings')}</div>
                </Link>
                <Link
                  to={`/${locale}/profile/auth-log`}
                  className="flex h-[42px] min-w-[250px] cursor-pointer items-center justify-start rounded-[8px] pl-4 hover:bg-hover-nav"
                >
                  <LogIcon />
                  <div className="ml-2 text-primaryTextColor no-underline">{t('system:authorization_log')}</div>
                </Link>
                <li
                  onClick={() => {
                    themeStorage.setValue(theme === 'dark' ? 'light' : 'dark')
                    setShow(false)
                  }}
                  className="flex h-[42px] min-w-[250px] cursor-pointer items-center justify-start rounded-[8px] pl-4 hover:bg-hover-nav"
                >
                  {theme === 'dark' ? <ThemeLightIcon /> : <ThemeIcon />}
                  <a className="ml-2 text-primaryTextColor no-underline hover:text-primaryTextColor">
                    {t('system:change_theme')}
                  </a>
                </li>
                <li
                  {...actionProps(() => void actions.LOGOUT())}
                  className="flex h-[42px] min-w-[250px] cursor-pointer items-center justify-start rounded-[8px] pl-4 hover:bg-hover-nav"
                  data-testid="logout-button"
                >
                  <LogoutIcon />
                  <a className="ml-2 text-primaryTextColor no-underline hover:text-primaryTextColor">
                    {t('system:log_out')}
                  </a>
                </li>
              </ul>
              <span className="mt-1 block px-[25px] text-center text-captionColor lg:hidden">
                {activeSemester?.name} {userState.data != null && '|'} {userState.data?.regime.name}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </header>
  )
}
